import React from 'react'
import styled from 'styled-components'
import Block from './Block'
import maryanna from '../assets/images/story/truelayer/maryanna-rayko.png'
import martin from '../assets/images/story/pleo/martin.png'
import dan from '../assets/images/story/skutopia/dan.png'
import dave from '../assets/images/quotes/dave.png'
import tatiana from '../assets/images/story/prima/tatiana.png'
import josep from '../assets/images/story/factorial/josep.png'
import QuoteGridBlock from './QuoteGridBlock'

const Testimonials = () => {
  return (
    <TestimonialsContainer>
      <Block type="padding-large" size="large">
        <div className="layout-index">
          <h3>Here’s what our customers are saying</h3>
        </div>
        <QuotesGrid>
          <QuoteGridBlock
            quote="Swarmia aligns with our way of thinking about the importance of empowering teams and checks all the boxes I have for a tool like this."
            name="Maryanna Rayko"
            title="VP of Engineering"
            photo={maryanna}
            company="TrueLayer"
          />
          <QuoteGridBlock
            quote="Swarmia gives us a shortcut into the visibility our teams need to improve their workflows."
            name="Martin Jagusch"
            title="Product Manager, Platform Team"
            photo={martin}
            company="Pleo"
          />
          <QuoteGridBlock
            quote="We’re using Swarmia to improve anything and everything we can within our software development lifecycle."
            name="Dan Gaskin"
            title="Engineering Manager"
            photo={dan}
            company="SKUTOPIA"
          />
          <QuoteGridBlock
            quote="The combination of quantitative developer insights with periodic qualitative feedback from developer surveys is powerful. Swarmia allows us to pinpoint specific areas for improvement and celebrate our successes."
            name="Dave Cumberland"
            title="CTO"
            photo={dave}
            company="Axios HQ"
          />
          <QuoteGridBlock
            quote="Since we started using Swarmia, we’ve seen huge improvements in our ways of working and all our engineering metrics."
            name="Tatiana Pipino"
            title="Technical Program Manager"
            photo={tatiana}
            company="Prima"
          />
          <QuoteGridBlock
            quote="Swarmia’s GitHub-to-Slack notifications are way better than in any other tool. It’s one of the reasons our engineers really love the product."
            name="Josep Jaume Rey"
            title="Senior Engineering Director"
            photo={josep}
            company="Factorial"
          />
        </QuotesGrid>
      </Block>
    </TestimonialsContainer>
  )
}

export default Testimonials

const TestimonialsContainer = styled.div`
  border-top: 1px rgba(0, 0, 0, 0.05) solid;
  h3 {
    text-align: center;
  }
`

const QuotesGrid = styled.div`
  -webkit-columns: 360px 3;
  -moz-columns: 360px 3;
  columns: 360px 3;
  -webkit-column-gap: 24px;
  -moz-column-gap: 24px;
  column-gap: 24px;
  margin: 0 auto;
  overflow: visible;
  display: block;
  -moz-column-fill: balance;
  column-fill: balance;
`
